import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ReadyForApprovBox = styled(Box)`
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin: -4px 0 0 10px;
`;
