import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Stack, Toolbar, Typography } from '@mui/material';
import { withErrorBoundary } from 'react-error-boundary';
import CubeLogo from '../../assets/CubeLogo.svg';
import UserMenu from '../UserMenu/UserMenu';
import NavMenu from '../NavMenu/NavMenu';
import SubNavBar from '../SubNavBar/SubNavBar';
import AlertMessage from '../../components/AlertMessage/AlertMessage';

const NavBar = ({ initials, handleLogout, subNavTitle, subNavItems, unauthorized }) => {
  return (
    <>
      <AppBar
        color="primary"
        position="fixed"
        sx={{
          height: '6.5rem',
          boxShadow: `0 0.1rem 0.3rem 0.1rem rgba(0, 0, 0, 0.15)`,
        }}
      >
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Stack
            component={Link}
            to="/"
            pl={2}
            gap={1}
            direction="row"
            alignItems="center"
            sx={{ textDecoration: 'none' }}
          >
            <img src={CubeLogo} alt="Cube logo in navigation bar" height={32} width={28} />
            <Typography component="h5" variant="h5" color="background.default" lineHeight={1.375}>
              cube
            </Typography>
          </Stack>
          <Stack direction="row">
            <UserMenu initials={initials} handleLogout={handleLogout} />
            {!unauthorized && <NavMenu />}
          </Stack>
        </Toolbar>
        <SubNavBar title={subNavTitle} navItems={subNavItems} />
      </AppBar>
    </>
  );
};

NavBar.defaultProps = {
  unauthorized: false,
};

NavBar.propTypes = {
  initials: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  subNavTitle: PropTypes.string,
  subNavItems: PropTypes.array,
  unauthorized: PropTypes.bool,
};

export default withErrorBoundary(NavBar, {
  fallback: <AlertMessage />,
});
