import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem } from '@mui/material';
import * as S from './UserMenu.styles';

const UserMenu = ({ handleLogout, initials }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <S.StyledIconButton active={anchorEl} onClick={handleClick}>
        <S.StyledAvatar>{initials}</S.StyledAvatar>
      </S.StyledIconButton>

      <S.StyledMenu
        elevation={0}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout} disableRipple>
          <Typography component="span" variant="body2" lineHeight="1rem">
            Logout
          </Typography>
        </MenuItem>
      </S.StyledMenu>
    </>
  );
};

UserMenu.propTypes = {
  handleLogout: PropTypes.any,
  initials: PropTypes.string,
};

export default UserMenu;
