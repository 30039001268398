import React from 'react';
import PropTypes from 'prop-types';
import { Typography, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NavMenuItem = ({ handleClose, logo, title, logoAltText, navTo }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (navTo.startsWith('http')) {
      window.location.href = navTo;
    } else {
      navigate(navTo);
    }
    handleClose();
  };

  return (
    <MenuItem onClick={handleClick} disableRipple>
      {logo && <img src={logo} alt={logoAltText} height={24} />}
      {title && (
        <Typography component="span" variant="body2" lineHeight="1rem" ml={1}>
          {title}
        </Typography>
      )}
    </MenuItem>
  );
};

NavMenuItem.defaultProps = {
  logoAltText: 'Product logo',
};

NavMenuItem.propTypes = {
  handleClose: PropTypes.func.isRequired,
  logo: PropTypes.node,
  title: PropTypes.string,
  logoAltText: PropTypes.string,
  navTo: PropTypes.string.isRequired,
};

export default NavMenuItem;
