import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import ValueWithCopy from '../ValueWithCopy/ValueWithCopy';

const getFieldValue = ({ withCopyIcon, value }) => {
  const fieldValue = (
    <Typography component="label" variant="body2" lineHeight="1.14rem" overflow="hidden">
      {value}
    </Typography>
  );

  return withCopyIcon ? <ValueWithCopy value={value}>{fieldValue}</ValueWithCopy> : fieldValue;
};

const VehicleDetailsField = ({
  label,
  value,
  tooltipText,
  withCopyIcon,
  isEllipsisWithTooltip,
}) => (
  <Stack direction="row" alignItems="center" py={1.5} spacing={3}>
    <Typography
      component="label"
      variant="body2"
      color="grey.light"
      flex={1}
      lineHeight="1.14rem"
      width={'40%'}
      minWidth={'40%'}
    >
      {label}
    </Typography>
    <Stack width={'60%'} alignItems="flex-start" overflow="hidden">
      {getFieldValue({ withCopyIcon, value, tooltipText, isEllipsisWithTooltip })}
    </Stack>
  </Stack>
);

VehicleDetailsField.defaultProps = {
  tooltipText: '',
  withCopyIcon: false,
};

VehicleDetailsField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.number, PropTypes.string, PropTypes.bool])
    .isRequired,
  tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  withCopyIcon: PropTypes.bool,
  isEllipsisWithTooltip: PropTypes.bool,
};

export default VehicleDetailsField;
