import React from 'react';
import PropTypes from 'prop-types';
import { getReadableDate } from 'utils/date.utils';

const DateDisplay = ({ date, format }) => {
  return <div>{getReadableDate(date, format)}</div>;
};

DateDisplay.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.any,
};

export default DateDisplay;
