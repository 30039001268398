import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Stack, Typography } from '@mui/material';

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: '1.5rem',
      height: '1.5rem',
      fontSize: '0.75rem',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
};

const AvatarName = ({ name, showAvatar }) => {
  return (
    <>
      {name ? (
        <Stack direction={'row'} spacing={1}>
          {showAvatar && <Avatar {...stringAvatar(name)} />}
          <Typography variant="body2">{name}</Typography>
        </Stack>
      ) : (
        <Typography variant="caption">Unassigned</Typography>
      )}
    </>
  );
};

AvatarName.defaultProps = {
  showAvatar: false,
};

AvatarName.propTypes = {
  name: PropTypes.string.isRequired,
  showAvatar: PropTypes.bool,
};

export default AvatarName;
