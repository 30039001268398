import React from 'react';
import { Box, Stack } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import PropTypes from 'prop-types';
import NavBar from 'components/NavBar/NavBar';
import messages from './errorPage.config.json';

const ErrorPage = ({ type, hideNavBar }) => {
  const auth = useAuth();
  const typeMessages = messages.find((msg) => msg.type === type) || messages[0];
  return (
    <>
      {!hideNavBar && (
        <NavBar
          page="error"
          handleLogout={() => {
            auth.signoutRedirect();
          }}
        />
      )}
      <Box mt={hideNavBar ? 0 : 13}>
        <Stack justifyContent="center" alignItems="center" minHeight="calc(100vh - 5.25rem)">
          <ErrorCard
            message={typeMessages.message}
            details={typeMessages.details}
            subDetails={typeMessages.subDetails}
          />
        </Stack>
      </Box>
    </>
  );
};

ErrorPage.defaultProps = {
  type: 'problems',
  hideNavBar: false,
};

ErrorPage.propTypes = {
  type: PropTypes.oneOf(['404', 'problems', 'authorization']),
  hideNavBar: PropTypes.bool,
};

export default ErrorPage;
