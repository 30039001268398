import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Stack, MenuItem, Menu, Typography } from '@mui/material';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import * as S from './SubNavBar.styles';

const SubNavMenu = ({ isMobile, label, items }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isNavMenuActive = useMemo(
    () => items?.some((item) => item.link === window.location.pathname),
    [items],
  );

  return (
    <Stack height="100%" width={isMobile && '100%'}>
      <S.StyledSubNavDropdown
        ismobile={+isMobile || 0}
        variant="text"
        endIcon={
          open ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="inherit" />
          )
        }
        className={isNavMenuActive ? 'active' : ''}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disableRipple
      >
        <Typography component="span" variant="body2">
          {label}
        </Typography>
      </S.StyledSubNavDropdown>
      {open && (
        <Menu
          open={open}
          anchorEl={anchorEl}
          slotProps={{
            elevation: 1,
            style: { borderRadius: '0rem' },
          }}
          MenuListProps={{
            style: { padding: '0rem' },
          }}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          {items?.map((item) => (
            <MenuItem
              key={`subNavMenuItem-${item.link}`}
              onClick={() => {
                setAnchorEl(null);
              }}
              sx={{ padding: '0rem' }}
            >
              <S.StyledSubNavMenuItem
                className={({ isActive }) => (isActive ? 'active' : '')}
                to={item.link}
              >
                {item.label}
              </S.StyledSubNavMenuItem>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Stack>
  );
};

SubNavMenu.propTypes = {
  isMobile: PropTypes.bool,
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SubNavMenu;
