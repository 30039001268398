import React, { useState } from 'react';
import { Apps as AppsIcon } from '@mui/icons-material';
import { Divider } from '@mui/material';
import * as S from './NavMenu.styles';
import CubeLogo from '../../assets/CubeLogo.svg';
import PipelineLogo from '../../assets/PipelineLogo.svg';
import ArrivalLogo from '../../assets/ArrivalLogo.svg';
import { getBaseURL } from '../../utils/environment';
import NavMenuItem from '../NavMenuItem/NavMenuItem';

const BASE_URL = getBaseURL();

const NavMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <S.StyledApps active={anchorEl} onClick={handleClick}>
        <AppsIcon />
      </S.StyledApps>
      <S.StyledMenu
        elevation={0}
        marginThreshold={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <NavMenuItem
          title="cube"
          handleClose={handleClose}
          logo={CubeLogo}
          logoAltText="Cube logo"
          navTo={`https://${BASE_URL}/`}
        />
        <Divider />

        <NavMenuItem
          handleClose={handleClose}
          logo={ArrivalLogo}
          logoAltText="Arrivals"
          title="arrival"
          navTo={`https://arrival.${BASE_URL}/`}
        />

        <Divider />

        <NavMenuItem
          title="pipeline"
          handleClose={handleClose}
          logo={PipelineLogo}
          logoAltText="Pipeline logo"
          navTo="/"
        />
      </S.StyledMenu>
    </>
  );
};

export default NavMenu;
