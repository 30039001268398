import styled from '@emotion/styled';
import { Menu, ButtonBase } from '@mui/material';

export const StyledMenu = styled(Menu)`
  && .MuiDivider-root {
    margin: 0;
    border-color: #dbdcde0d;
    border-bottom-width: 0.0625rem;
  }
  & .MuiPaper-root {
    border-radius: 0;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    min-width: 16rem;
    color: ${({ theme }) => theme.palette.background.paper};
    & .MuiList-root {
      padding-top: 0;
      padding-bottom: 0;
    }
    & .MuiMenuItem-root {
      &:hover {
        background-color: ${({ theme }) => theme.palette.secondary.dark};
      }
    }
  }
`;

export const StyledApps = styled(ButtonBase)`
  background: ${({ active, theme }) => (active ? theme.palette.secondary.dark : 'transparent')};
  padding: 1rem;
  :hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`;
