import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';

const DrawerBox = ({ open, hideBackdrop, handleClose, children, noPaddingTop }) => {
  const theme = useTheme();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      elevation={8}
      sx={{
        zIndex: `${
          theme.components.MuiAppBar.styleOverrides.root.zIndex - (noPaddingTop ? -1 : 1)
        }`,
        ['& .MuiBackdrop-root']: { opacity: `${hideBackdrop ? '0 !important' : '1'}` },
      }}
      PaperProps={{
        sx: {
          justifyContent: 'space-between',
          paddingTop: noPaddingTop
            ? '0'
            : `${theme.components.MuiAppBar.styleOverrides.root.height}`,
          width: {
            mobile: '100%',
            tablet: '50%',
          },
        },
      }}
    >
      {children}
    </Drawer>
  );
};

DrawerBox.propTypes = {
  open: PropTypes.bool.isRequired,
  hideBackdrop: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

DrawerBox.defaultProps = {
  hideBackdrop: false,
};

export default DrawerBox;
