import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import { Box, Divider, Stack, Typography, Paper } from '@mui/material';
import AlertMessage from '../AlertMessage/AlertMessage';
import VehicleDetailsField from '../VehicleDetailsField/VehicleDetailsField';

const VehicleDetailsTable = ({ title, attributes, oneColumn, ...rest }) => {
  const twoColumnView = () => {
    const mid = Math.ceil(attributes.length / 2);
    return attributes.slice(0, mid).map((firstCol, index) => {
      const secondCol = attributes[index + mid];

      return (
        <Stack key={firstCol?.label} direction="row" alignItems="center" spacing={2}>
          <Box flex={1} minWidth={0}>
            <VehicleDetailsField
              label={firstCol?.label}
              value={firstCol?.value}
              withCopyIcon={firstCol?.withCopyIcon}
            />
          </Box>
          {secondCol ? (
            <Box flex={1} minWidth={0}>
              <VehicleDetailsField
                label={secondCol?.label}
                value={secondCol?.value}
                withCopyIcon={secondCol?.withCopyIcon}
              />
            </Box>
          ) : (
            <Box flex={1}>
              <VehicleDetailsField label="" value="" />
            </Box>
          )}
        </Stack>
      );
    });
  };

  const oneColumnView = () => {
    return attributes.map((col) => {
      return (
        <Stack key={col?.label} direction="row" alignItems="center" spacing={2}>
          <Box flex={1} minWidth={0}>
            <VehicleDetailsField
              label={col?.label}
              value={col?.value}
              withCopyIcon={col?.withCopyIcon}
            />
          </Box>
        </Stack>
      );
    });
  };

  return (
    <Paper
      component={Stack}
      divider={<Divider />}
      elevation={0}
      sx={{ backgroundColor: 'background.default' }}
      {...rest}
    >
      <Typography component="h5" variant="body1" py={1.5} px={2} lineHeight="1rem">
        {title}
      </Typography>
      <Stack px={2}>{oneColumn ? oneColumnView() : twoColumnView()}</Stack>
    </Paper>
  );
};

VehicleDetailsTable.defaultProps = {
  attributes: [],
};

VehicleDetailsTable.propTypes = {
  title: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.node,
      ]).isRequired,
      tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      withCopyIcon: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  oneColumn: PropTypes.bool,
  isEllipsisWithTooltip: PropTypes.bool,
};

export default withErrorBoundary(VehicleDetailsTable, {
  fallback: <AlertMessage />,
});
