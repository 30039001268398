import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { useQueries } from '@tanstack/react-query';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { getBaseURL } from '../utils/environment';

const rolesApiUrl = `https://api-spo.${getBaseURL()}/v1/user`;
const getRoles = (token) => axios({ url: rolesApiUrl, headers: { Authorization: token } });

const heroApiUrl = `https://api-spo.${getBaseURL()}/v1/hero`;
const getHero = (token) => axios({ url: heroApiUrl, headers: { Authorization: token } });

export const PreloadContext = createContext({});

const PreloadProvider = ({ children }) => {
  const auth = useAuth();

  const [roles, hero] = useQueries({
    queries: [
      {
        queryFn: () => getRoles(auth?.user?.access_token),
        queryKey: ['user', auth?.user?.access_token, auth?.events],
        enabled: !!auth?.isAuthenticated,
        retry: 0,
      },
      {
        queryFn: () => getHero(auth?.user?.access_token),
        queryKey: ['hero'],
        enabled: !!auth?.isAuthenticated,
        retry: 0,
      },
    ],
  });

  const handleLogout = () => {
    auth.signoutSilent();
    window.location.replace(window.location.origin);
  };

  if (roles.isInitialLoading || hero.isInitialLoading) return <LoadingSpinner fullScreen />;

  if (roles.isError || hero.isError) {
    return (
      <>
        <NavBar subNavTitle="Sold Order" handleLogout={handleLogout} unauthorized />
        <ErrorPage type="userAuthorization" hideNavBar />
      </>
    );
  }

  return (
    <PreloadContext.Provider value={{ roles: roles.data?.data?.roles || [], data: '' }}>
      {children}
    </PreloadContext.Provider>
  );
};

PreloadProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PreloadProvider;
