import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  .MuiTooltip-tooltip {
    background: ${(props) => props.theme.palette.primary.main};
    padding: 1rem 0.75rem;
  }

  .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
