import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

const AlertMessage = ({ title, msg, severity }) => (
  <Alert severity={severity}>
    <AlertTitle>{title}</AlertTitle>
    {msg}
  </Alert>
);

AlertMessage.defaultProps = {
  title: 'Error',
  msg: 'There was an issue loading the data. If this error persists - please contact the iTX team.',
  severity: 'error',
};

AlertMessage.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  severity: PropTypes.string,
};

export default AlertMessage;
