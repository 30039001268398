const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };

export const getReadableDate = (_date, format, isTime) => {
  if (_date) {
    if (!isTime) {
      let dateFormat = new Date(_date);
      dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset());

      return dateFormat.toLocaleDateString('en-US', format ? format : dateOptions);
    } else {
      return new Date(_date).toLocaleString('en-US', format);
    }
  } else {
    return '-';
  }
};

export const getMonthFromDate = (_date) => {
  if (_date) {
    let dateFormat = new Date(_date);
    dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset());

    return dateFormat.toLocaleDateString('en-US', { month: 'long' });
  } else {
    return '-';
  }
};

export const getMonthYearFromDate = (_date) => {
  let dateFormat = new Date(_date);
  dateFormat.setMinutes(dateFormat.getMinutes() + dateFormat.getTimezoneOffset());

  return dateFormat.toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  });
};

export const getLocalTime = (_date) => {
  if (_date) {
    return new Date(_date).toLocaleTimeString('en-US');
  } else {
    return '-';
  }
};

export const getLocalDate = (_date) => {
  if (_date) {
    return new Date(_date).toLocaleDateString('en-US');
  } else {
    return '-';
  }
};
