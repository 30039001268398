import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableRow,
  Paper,
} from '@mui/material';

const AuditTable = ({ id, stats }) => {
  return (
    <Box p={4} pt={2}>
      <Typography variant="h4" component="h4">
        Audit Logs for SPO #{id}
      </Typography>

      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell align="left">Event ID</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">User</TableCell>
              <TableCell align="left">Timestamp</TableCell>
            </TableHead>
            <TableBody>
              {stats?.map((stat) => (
                <TableRow
                  key={stat.spo_stat_sk}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="left">
                    {stat.spo_stat_sk}
                  </TableCell>
                  <TableCell align="left">{stat.stat}</TableCell>
                  <TableCell align="left">{stat.aud_crt_id}</TableCell>
                  <TableCell align="left">
                    {new Date(stat.aud_crt_ts).toLocaleString('en-us')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

AuditTable.propTypes = {
  id: PropTypes.number.isRequired,
  stats: PropTypes.array.isRequired,
};

export default AuditTable;
