import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import AlertMessage from '../AlertMessage/AlertMessage';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ClearOutlined } from '@mui/icons-material';
import ToyotaLogo from '../../assets/ToyotaLogo.svg';
import LexusLogo from '../../assets/LexusLogo.svg';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import { ReadyForApprovBox } from './VehicleDetailsHeader.styles';
import { STATUS_CHIPS } from 'utils/enums';
import theme from 'theme';

const VehicleDetailsHeader = ({ vehicle, closeHandler, statusChips }) => {
  const logo = vehicle.brand.toUpperCase() === 'TOYOTA' ? ToyotaLogo : LexusLogo;
  const { READY_FOR_APPROVAL, EXPIRED, CUSTOMER_CONFIRMED, EXTRACTED } = STATUS_CHIPS;
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} mr="0.5rem" alignItems="center">
          <Box mt={1} width="2.625rem">
            <img src={logo} alt={`${vehicle.brand}-logo`} />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant="h2" component="span">
              {vehicle.soldOrderNumber.toUpperCase()}
            </Typography>
            <Typography variant="body1" component="span" color="grey.dark">
              {`${vehicle.brand.toUpperCase()} ${vehicle.series} ${vehicle.modelYear}  ${
                vehicle.grade
              }`}
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={closeHandler}>
          <ClearOutlined color="primary" fontSize="medium" />
        </IconButton>
      </Stack>
      {statusChips && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Stack direction="row" spacing={2} mr="0.5rem" alignItems="center">
            {/* <Box mt={1} width="2.625rem">
              <img src={logo} alt={`${vehicle.brand}-logo`} />
            </Box> */}
            <Stack spacing={0.5}>
              {/* <Typography variant="h2" component="span">
                {vehicle.soldOrderNumber.toUpperCase()}
              </Typography> */}
              <Typography
                variant="caption"
                component="span"
                color="grey.dark"
                style={{ display: 'inline-flex' }}
              >
                Status:
                {
                  <ReadyForApprovBox
                    component="span"
                    sx={{
                      backgroundColor:
                        statusChips === READY_FOR_APPROVAL
                          ? `${theme.palette.chip.fleetChipBackground}`
                          : statusChips === EXPIRED
                          ? `${theme.palette.chip.expiredBackground}`
                          : statusChips === CUSTOMER_CONFIRMED
                          ? `${theme.palette.chip.currentSaleBackground}`
                          : statusChips === EXTRACTED
                          ? `${theme.palette.chip.extractedBackground}`
                          : ``,
                      color:
                        statusChips === READY_FOR_APPROVAL
                          ? `${theme.palette.info.main}`
                          : statusChips === EXPIRED
                          ? `${theme.palette.red.main}`
                          : statusChips === CUSTOMER_CONFIRMED
                          ? `${theme.palette.chip.currentSaleText}`
                          : statusChips === EXTRACTED
                          ? `${theme.palette.background.paper}`
                          : ``,
                    }}
                  >
                    {statusChips === READY_FOR_APPROVAL && (
                      <ArrowCircleRightOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {statusChips === EXPIRED && (
                      <CloseOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {(statusChips === CUSTOMER_CONFIRMED || statusChips === EXTRACTED) && (
                      <CheckOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {statusChips}
                  </ReadyForApprovBox>
                }
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

VehicleDetailsHeader.defaultProps = {};

VehicleDetailsHeader.propTypes = {
  vehicle: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  statusChips: PropTypes.string,
};

export default withErrorBoundary(VehicleDetailsHeader, {
  fallback: <AlertMessage />,
});
