import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, useTheme } from '@mui/material';
import * as S from '../CurrentExtractWindow/CurrentExtractWindow.styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const Status = ({ status }) => {
  const theme = useTheme();
  const statuses = [
    {
      status: 'pending customer confirmation',
      borderColor: 'pending',
      iconComponent: <AutorenewIcon fontSize="small" sx={{ color: theme.palette.warning.main }} />,
    },
    {
      status: 'customer confirmed',
      borderColor: 'customerConfirmed',
      iconComponent: <LockOutlinedIcon fontSize="small" sx={{ color: theme.palette.info.main }} />,
    },
    {
      status: 'extracted',
      borderColor: 'extracted',
      iconComponent: <DoneOutlinedIcon fontSize="small" color="success" />,
    },
    {
      status: 'extraction in process',
      borderColor: 'extractionInProcess',
      iconComponent: (
        <AutorenewIcon fontSize="small" sx={{ color: theme.palette.action.disabled }} />
      ),
    },
    {
      status: 'expired',
      borderColor: 'expired',
      iconComponent: <ClearOutlinedIcon fontSize="small" sx={{ color: theme.palette.red.main }} />,
    },
  ];

  let statusData = statuses.find((s) => s.status.toLowerCase() === status.toLowerCase());

  // if (!statusData) {
  //   statusData = statuses.find((s) => s.status.toLowerCase() === 'customer confirmed');
  // }

  return (
    <Box>
      {statusData && (
        <S.StyledSpan borderColor={statusData.borderColor}>{statusData.iconComponent}</S.StyledSpan>
      )}
      <Typography variant="body3">{status}</Typography>
    </Box>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
