import { spoApi } from 'dataaccess/axios.service';

export const getSpoStatusCount = async (token) => {
  const resp = await spoApi.get(`/v2/status-count`, {
    headers: {
      Authorization: token,
    },
  });

  return resp?.data?.payload || {};
};

export const getSpoOrders = async (token, queryParams) => {
  const resp = await spoApi.get(`/v2/orders/current${queryParams}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp?.data || {};
};

export const getSpoDashboard = async (token) => {
  const resp = await spoApi.get(`/v2/approved-orders`, {
    headers: {
      Authorization: token,
    },
  });
  return resp?.data?.payload || [];
};

/**
 *
 * @param {*} token - AuthToken
 * @param {*} gropuStatus - Group Status Value
 * @param {*} status - Individual SPO Status
 * @param {*} limit - Page Limit
 * @param {*} offset - Page Offset/Number
 * @param {*} filter - Filter Object
 * @param {*} order - Order By Object
 * @returns
 */
export const getSpoList = async (token, groupStatus, status, limit, offset, filter, order) => {
  let queryParams = status ? `?status=${status}` : `?groupStatus=${groupStatus}`;
  queryParams += `&limit=${limit}&offset=${offset}`;

  if (filter) {
    queryParams += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
  }

  if (order) {
    queryParams += `&orderBy=${encodeURIComponent(JSON.stringify(order))}`;
  }
  const resp = await spoApi.get(`/v2/orders/current${queryParams}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp?.data?.payload || [];
};

export const getCurrentCycle = async (token) => {
  const resp = await spoApi.get(`/v2/current-cycle`, {
    headers: {
      Authorization: token,
    },
  });

  return resp?.data?.payload || {};
};

export const getSpoQuotaUsageDetails = async (token) => {
  const resp = await spoApi.get(`/v2/quota-usage`, {
    headers: {
      Authorization: token,
    },
  });

  return resp?.data?.payload || {};
};

export const getCancelledSpoCount = async (token) => {
  const resp = await spoApi.get(`/v2/cancelled-orders`, {
    headers: {
      Authorization: token,
    },
  });

  return resp?.data?.payload || [];
};

export const approveSpo = async (token, requestPayload) => {
  const resp = await spoApi.post(`/v2/approve`, requestPayload, {
    headers: {
      Authorization: token,
    },
  });

  return resp;
};

export const rejectSpo = async (token, requestPayload) => {
  const resp = await spoApi.post(`/v2/reject`, requestPayload, {
    headers: {
      Authorization: token,
    },
  });

  return resp;
};

export const getSpoDetails = async (token, orderId) => {
  const resp = await spoApi.get(`/v2/order/${orderId}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp?.data?.payload || [];
};
