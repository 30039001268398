import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import SubNavMenu from './SubNavMenu';
import * as S from './SubNavBar.styles';

const LinkWrapper = ({ navItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = (event) => {
    setAnchorEl((prevState) => (prevState ? null : event.currentTarget.parentElement));
  };

  if (!isMobile) {
    return navItems?.map((navItem, index) => (
      <Fragment key={`subNavMenu-${index}`}>
        {navItem.subItems?.length ? (
          <SubNavMenu label={navItem.label} items={navItem.subItems} />
        ) : (
          <S.StyledSubNavLink
            className={({ isActive }) => (isActive ? 'active' : '')}
            to={navItem.link}
            end
          >
            {navItem.label}
          </S.StyledSubNavLink>
        )}
      </Fragment>
    ));
  }

  return (
    navItems.length && (
      <Box ml="auto">
        <S.StyledIconButton active={anchorEl} onClick={handleToggle}>
          {anchorEl ? <MenuOpenIcon /> : <MenuIcon />}
        </S.StyledIconButton>

        <S.StyledMenu
          elevation={1}
          marginThreshold={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleToggle}
        >
          {navItems.map((navItem, index) => (
            <MenuItem key={`subNavLink-${index}`} width="100%">
              {navItem.subItems?.length ? (
                <SubNavMenu isMobile={isMobile} label={navItem.label} items={navItem.subItems} />
              ) : (
                <S.StyledSubNavLink
                  ismobile={isMobile.toString()}
                  className={({ isActive }) => (isActive ? 'active' : '')}
                  to={navItem.link}
                  end
                >
                  {navItem.label}
                </S.StyledSubNavLink>
              )}
            </MenuItem>
          ))}
        </S.StyledMenu>
      </Box>
    )
  );
};

LinkWrapper.propTypes = {
  navItems: PropTypes.array,
};

const SubNavBar = ({ title, navItems }) => (
  <Toolbar
    sx={{
      backgroundColor: 'background.paper',
      minHeight: '3rem',
    }}
    disableGutters
  >
    <Box
      display="flex"
      width="100%"
      height="100%"
      maxWidth="12.5rem"
      alignItems="center"
      px={2}
      backgroundColor="primary.main"
    >
      <Typography component="h6" variant="h6" color="background.paper" lineHeight="1.375rem">
        {title}
      </Typography>
    </Box>
    <LinkWrapper navItems={navItems} />
  </Toolbar>
);

SubNavBar.propTypes = {
  title: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
};

export default SubNavBar;
