import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { withErrorBoundary } from 'react-error-boundary';
import { Typography, Box, Stack } from '@mui/material';
import AlertMessage from '../AlertMessage/AlertMessage';
import FailedVehicleImage from '../../assets/failed-image.png';

const StyledStack = styled(Stack)`
  background: linear-gradient(to bottom, #ded3ed 0%, #dde8ff 27%, rgba(208, 227, 238, 0) 100%);
`;

const StyledImage = styled.img`
  object-fit: contain;
`;

const VehicleDetailsImage = ({ modelDescription, image }) => {
  return (
    <StyledStack
      justifyContent="center"
      alignItems="center"
      spacing={{ mobile: '1.5rem', tablet: '1rem' }}
      px={{ mobile: '1rem', tablet: '6rem' }}
      pt={{ mobile: '2rem', tablet: '0rem' }}
      pb={{ mobile: '2rem', tablet: '1rem' }}
    >
      <Box
        display="flex"
        data-testid="next-button"
        justifyContent="center"
        maxHeight={{ mobile: '9.5rem', tablet: '16.125rem' }}
        width="100%"
        mb={{ mobile: '0.5rem', tablet: '0rem' }}
      >
        <StyledImage
          data-testid="vehicle-image"
          src={image || FailedVehicleImage}
          alt="vehicle-image"
          width="100%"
        />
      </Box>
      {modelDescription && (
        <Typography data-testid="vehicle-info" variant="button" component="span">
          {modelDescription}
        </Typography>
      )}
    </StyledStack>
  );
};

VehicleDetailsImage.defaultProps = {
  image: FailedVehicleImage,
};

VehicleDetailsImage.propTypes = {
  modelDescription: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default withErrorBoundary(VehicleDetailsImage, {
  fallback: <AlertMessage />,
});
