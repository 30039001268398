import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import AuditTable from 'views/AuditTable/AuditTable';
import DrawerBox from 'components/DrawerBox/DrawerBox';
import VehicleDetailsHeader from 'components/VehicleDetailsHeader/VehicleDetailsHeader';
import VehicleDetailsImage from 'components/VehicleDetailsImage/VehicleDetailsImage';
import VehicleDetailsTable from 'components/VehicleDetailsTable/VehicleDetailsTable';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { getBaseURL } from 'utils/environment';

const getData = async (spoSk, token) => {
  return axios({
    url: `https://api-spo.${getBaseURL()}/v1/order/${spoSk}`,
    headers: {
      Authorization: token,
    },
  });
};

const VehicleDrawer = ({ open, spoSk, handleClose }) => {
  const auth = useAuth();

  const { isLoading, data } = useQuery({
    queryKey: [`${spoSk}-order`],
    queryFn: () => getData(spoSk, auth.user.access_token),
    enabled: !!open,
  });

  const vehicleData = data?.data;

  const vehicle = {
    status: vehicleData?.stat,
    brand: vehicleData?.brand_cd,
    soldOrderNumber: vehicleData?.so_nbr,
    specialOrderNumber: vehicleData?.spo_sk,
    modelYear: vehicleData?.mdl_yr,
    series: vehicleData?.sales_series_nm,
    grade: vehicleData?.grade_spec_nm,
    modelDescription: vehicleData?.mdl_desc,
    tomsOrderNumber: vehicleData?.toms_nbr,
    etpDate:
      vehicleData?.est_bld_dt &&
      new Date(vehicleData?.est_bld_dt)?.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        ...(vehicleData?.est_bld_dt_type === 'date' ? { day: 'numeric' } : {}),
        timeZone: 'UTC',
      }),
    loDate:
      vehicleData?.lo_dt &&
      new Date(vehicleData?.lo_dt)?.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      }),
    etaWindow:
      vehicleData?.eta_from_dt &&
      new Date(vehicleData?.eta_from_dt).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC',
      }) +
        ' - ' +
        new Date(vehicleData?.eta_to_dt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          timeZone: 'UTC',
        }),
    exteriorColorCode: vehicleData?.extr_clr_cd,
    interiorColorCode: vehicleData?.intr_clr_cd,
    fioAccessories: vehicleData?.fio_option_list,
    ppoAccessories: vehicleData?.ppo_option_list,
    stats: vehicleData?.stats?.sort((a, b) => b.spo_stat_sk - a.spo_stat_sk),
  };

  const customer = {
    firstName: vehicleData?.fst_nm,
    lastName: vehicleData?.last_nm,
    dealerCode: vehicleData?.dlr_cd,
    salesPerson: vehicleData?.dlr_sales_assoc,
  };

  return (
    <DrawerBox open={open} handleClose={handleClose}>
      {isLoading && (
        <Box p={2}>
          <LoadingSpinner />
        </Box>
      )}
      {open && !!vehicle?.specialOrderNumber && (
        <>
          <Box
            p={3}
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0.8px 2.9px 0.8px',
              position: 'sticky',
              top: 0,
              width: '100%',
              background: 'white',
            }}
          >
            <VehicleDetailsHeader vehicle={vehicle} closeHandler={handleClose} />
          </Box>

          <VehicleDetailsImage image="" modelDescription={vehicle.modelDescription} />

          <Box p={3}>
            <Typography variant="h4" mb={2}>
              Order Information
            </Typography>

            <VehicleDetailsTable
              title="Customer Information"
              attributes={[
                { label: 'First Name', value: customer.firstName ?? 'N/A' },
                { label: 'Last Name', value: customer.lastName ?? 'N/A' },
                { label: 'Dealer Code', value: customer.dealerCode ?? 'N/A' },
                { label: 'Sales Person', value: customer.salesPerson ?? 'N/A' },
              ]}
            />

            <Box mt={3}>
              <VehicleDetailsTable
                title="Order Details"
                attributes={[
                  { label: 'Status', value: vehicle?.stats?.[0]?.stat ?? 'N/A' },
                  { label: 'SO No.', value: vehicle.soldOrderNumber ?? 'N/A', withCopyIcon: true },
                  { label: 'SPO No.', value: vehicle.specialOrderNumber ?? 'N/A' },
                  { label: 'TOMS Order No.', value: vehicle.tomsOrderNumber ?? 'N/A' },
                  {
                    label: 'Est. Prod Date',
                    value: vehicle.etpDate || 'N/A',
                  },
                  {
                    label: 'LO Request Date',
                    value: vehicle.loDate || 'N/A',
                  },
                  {
                    label: 'ETA Window',
                    value: vehicle.etaWindow || 'N/A',
                  },
                ]}
              />
            </Box>

            <Box mt={3}>
              <VehicleDetailsTable
                title="Vehicle Details"
                attributes={[
                  {
                    label: 'Ext Color',
                    value: vehicle.exteriorColorCode ?? 'N/A',
                  },
                  {
                    label: 'Int Color',
                    value: vehicle.interiorColorCode ?? 'N/A',
                  },
                  { label: 'FIO', value: vehicle.fioAccessories ?? 'N/A' },
                  { label: 'PPO', value: vehicle.ppoAccessories ?? 'N/A' },
                ]}
              />
            </Box>
          </Box>

          <AuditTable id={vehicle?.specialOrderNumber} stats={vehicle.stats} />
        </>
      )}
    </DrawerBox>
  );
};

VehicleDrawer.propTypes = {
  open: PropTypes.bool,
  spoSk: PropTypes.string,
  handleClose: PropTypes.func,
};

export default VehicleDrawer;
