const url = window.location.origin;

export const getEnv = () => {
  switch (true) {
    case url.includes('one.nso.toyota.com'):
      return 'prod';
    case url.includes('one.nsouat.toyota.com'):
      return 'uat';
    case url.includes('one.nsoqa.toyota.com'):
      return 'qa';
    case url.includes('one.nsodev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'dev';
  }
};

export const getBaseURL = () =>
  ({
    dev: 'one.nsodev.toyota.com',
    qa: 'one.nsoqa.toyota.com',
    uat: 'one.nsouat.toyota.com',
    prod: 'one.nso.toyota.com',
  }[getEnv()]);

// export const getDCBaseURL = () =>
//   ({
//     dev: '7seugqpyxfbollw5kmvfpoeayi.appsync-api.us-west-2.amazonaws.com',
//     qa: '7seugqpyxfbollw5kmvfpoeayi.appsync-api.us-west-2.amazonaws.com',
//     uat: '7seugqpyxfbollw5kmvfpoeayi.appsync-api.us-west-2.amazonaws.com',
//     prod: 'lwz7iannojgu3bji7nr67o5m2e.appsync-api.us-east-1.amazonaws.com',
//   }[getEnv()]);
