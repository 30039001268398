import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import { Button, Menu, ButtonBase } from '@mui/material';

export const StyledSubNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: ${({ ismobile }) => ismobile && '3rem'};
  width: ${({ ismobile }) => ismobile && '100%'};
  padding: 0 1rem;
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  color: ${({ theme }) => theme.palette.primary.main};

  &.active {
    border-bottom: ${({ theme }) => `0.125rem solid ${theme.palette.primary.main}`};
  }
  :hover {
    background: ${({ theme }) => theme.palette.background.light};
  }
`;

export const StyledSubNavDropdown = styled(Button)`
  ${({ ismobile }) =>
    ismobile &&
    css`
      width: 100%;
      justify-content: space-between;
    `};

  padding: 0.75rem 1rem;
  height: 100%;
  border-radius: 0rem;

  &.active {
    border-bottom: ${({ theme }) => `0.125rem solid ${theme.palette.primary.main}`};
  }
  &:hover {
    background: ${({ theme }) => theme.palette.background.ligh};
  }
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;

export const StyledSubNavMenuItem = styled(NavLink)`
  padding: 1rem;
  width: 100%;
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  color: ${({ theme }) => theme.palette.primary.main};

  &.active {
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  }
`;

export const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    min-width: 16rem;
    border-radius: 0;
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.background.paper};
    & .MuiList-root {
      padding-top: 0;
      padding-bottom: 0;
    }
    & .MuiMenuItem-root {
      padding: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const StyledIconButton = styled(ButtonBase)`
  background: ${({ active, theme }) => (active ? theme.palette.background.light : 'transparent')};
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 0.75rem 1rem;
  :hover {
    background: ${({ theme }) => theme.palette.background.light};
  }
`;
