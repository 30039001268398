import styled from '@emotion/styled';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import theme from '../../theme';

export const StyledSpan = styled.span(({ borderColor }) => ({
  border:
    borderColor === 'pending'
      ? `1px solid ${theme.palette.warning.main}`
      : borderColor === 'customerConfirmed'
      ? `1px solid ${theme.palette.info.main}`
      : borderColor === 'extracted'
      ? `1px solid ${theme.palette.success.main}`
      : borderColor === 'extractionInProcess'
      ? `1px solid ${theme.palette.action.disabled}`
      : borderColor === 'expired'
      ? `1px solid ${theme.palette.red.main}`
      : '1px solid #e66c02',
  padding: '0.5px 4px 0.5px 4px',
  borderRadius: '4px',
  marginRight: '8px',
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.default,
  },
  // hide last border
  // '&:last-child td': {
  //   border: 0,
  // },
}));
