import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CopyTooltip from '../CopyTooltip/CopyTooltip';
import * as S from './ValueWithCopy.styles';

const ValueWithCopy = ({ children, value, valueAlign }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <S.Stack direction="row" spacing={1} justifyContent={valueAlign} hovered={+hovered}>
      {children}
      <S.Box onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        <CopyTooltip content={value} />
      </S.Box>
    </S.Stack>
  );
};

ValueWithCopy.defaultProps = {
  valueAlign: 'start',
};

ValueWithCopy.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
  valueAlign: PropTypes.string,
};

export default ValueWithCopy;
