import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as S from './CopyTooltip.styles';

const CopyTooltip = ({ content }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(content);
    setOpen(true);
    setTimeout(() => setOpen(false), 800);
  };

  return (
    <S.StyledTooltip
      className="copy-icon"
      title={
        <Typography variant="body1" component="span">
          Copied to clipboard
        </Typography>
      }
      open={open}
      arrow
      placement="top"
    >
      <ContentCopyIcon
        className="MuiSvgIcon-ContentCopy"
        sx={{ fontSize: '0.875rem' }}
        onClick={handleTooltipOpen}
      />
    </S.StyledTooltip>
  );
};

CopyTooltip.propTypes = {
  content: PropTypes.string.isRequired,
};

export default CopyTooltip;
